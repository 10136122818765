import {Button} from "react-bootstrap";
import axios from "axios";

const DownloadButton = () => {

    const accessToken = localStorage.getItem('login');

    const clickHandler = async () => {

        // Volání serverového endpointu pro generování CSV souboru s přidáním tokenu v hlavičce
        const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

        axios.get(apiUrl + '/backend/admin/export.php', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            responseType: 'blob', // nastavení responseType na 'blob' pro správné zpracování binárních dat
        })
            .then(response => {
                // Vytvoření Blob objektu ze získaných dat
                if(response.status===200) {
                    const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                    // Vytvoření odkazu pro stahování
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'export.xlsx';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    // Uvolnění zdrojů Blob objektu
                    window.URL.revokeObjectURL(url);
                }
                else{
                    console.error('Chyba při stahování souboru');
                }
            })
            .catch(error => {
                console.error('Chyba při stahování souboru:', error);
            });
    }

    return (
        <div style={{display: "flex", justifyContent: "center", paddingTop: '40px'}}>
            <Button onClick={clickHandler} variant={"dark"}>Exportovat data (.xlsx)</Button>
        </div>
    )
}
export default DownloadButton;
