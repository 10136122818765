import React, {lazy, Suspense} from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import Preloader from "./components/UI/Preloader/Preloader";
import AdminPanelRoot from "./pages/admin/AdminPanelRoot";
import Admin from "./pages/admin/Admin";
import './App.css';
import Confirmation from "./pages/Confirmation";

const Root = lazy(() => import('./pages/Root'));
const Home = lazy(() => import('./pages/Home'));
const Registration = lazy(() => import('./pages/Registration'));
const Casting = lazy(() => import('./pages/Casting'));

const router = createBrowserRouter([
        {
            path: '/',
            id: 'root',
            element: <Root/>,
            children: [
                {
                    index: true,
                    element: <Home/>
                },
                {
                    path: '/registrace',
                    element: <Registration/>
                },
                {
                    path: '/casting',
                    element: <Casting/>
                },
                {
                    path: '/potvrzeni-registrace',
                    element: <Confirmation/>
                }
            ]
        },
        {
            path: '/_website_administration_login_',
            id: 'admin',
            element: <AdminPanelRoot/>,
            children: [
                {
                    index: true,
                    element: <Admin/>
                },
            ]
        }

    ]
)

const App = () => {

    return (
        <Suspense fallback={<Preloader/>}>
            <RouterProvider router={router}/>
        </Suspense>

    )
}


export default App;
