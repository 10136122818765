import {Hearts} from "react-loader-spinner";
import styles from './Preloader.module.css';

const Preloader = ({title = 'Načítám...'}) => {

    return (

        <div id={styles.preloader}>
            <Hearts
                height="150"
                width="150"
                color="#ff18d5"
                ariaLabel="hearts-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
            <p><strong>{title}</strong></p>
        </div>

    )
}

export default Preloader;
