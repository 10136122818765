import {Button, Container, Modal, Pagination, Table} from "react-bootstrap";
import axios from "axios";
import {Fragment, useEffect, useState} from "react";
import DownloadButton from "./DownloadButton";
import Input from "../../../components/UI/Input";


const List = (props) => {

    const [all, setAll] = useState([]);
    const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [listedData, setListedData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [search, setSearch] = useState(false);

    const [show, setShow] = useState(false);
    const [modalContent, setModalContent] = useState([]);
    const [removeConfirmation, setRemoveConfirmation] = useState(false)
    const [removeConfirmationContent, setRemoveConfirmationContent] = useState([])

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (id) => {

        if (props.checkAuth()) {
            setShow(true);
            const detailData = Object.values(all).filter((item) => {
                    return item.id === id
                }
            )
            setModalContent({...detailData[0]});
        }


    }

    const perPage = 50;

    // Rozdělení data na pole podle počtu stránek
    const chunkArray = (array, chunkSize) => {
        const result = [];

        while (array.length > 0) {
            result.push(array.splice(0, chunkSize));
        }


        return result;
    }

    // API - načtení dat
    const fetch = async () => {
        if (await props.checkAuth) {
            try {

                const formData = new FormData();
                formData.append('action', 'listdata');
                formData.append('token', localStorage.getItem('login'));
                const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

                const response = await axios({
                        url: apiUrl + '/backend/admin/data.php',
                        method: 'post',
                        data: formData,
                    }
                );

                if (response.data.status) {

                    setAll(Object.values(response.data.data));
                    const chunkedArray = chunkArray(response.data.data, perPage);
                    setData(chunkedArray);
                    setListedData(chunkedArray[0])
                    setDataLoaded(true);
                }

            } catch (error) {
                console.log('Chyba při zpracování požadavku!')
            }
        }
    }

    // Handler pro stažení souboru
    const downloadHandler = (event) => {

        const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
        axios.get(apiUrl + '/backend/api/data/pdf/' + event.target.dataset.file + '.pdf', {responseType: 'arraybuffer'})
            .then(response => {
                // Vytvoření Blob (Binary Large Object) ze stažených dat
                const blob = new Blob([response.data], {type: 'application/octet-stream'});

                // Vytvoření odkazu pro stažení souboru
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = event.target.dataset.file + '.pdf';

                // Přidání odkazu do DOMu
                document.body.appendChild(downloadLink);

                // Simulace kliknutí na odkaz pro spuštění stahování
                downloadLink.click();

                // Odstranění odkazu ze DOMu
                document.body.removeChild(downloadLink);
            })
            .catch(error => {
                console.error('Chyba při stahování souboru', error);
            });
    };

    // Fetch při první načtení stránky
    useEffect(() => {
        fetch();
    }, []);


    // Handler pro stránkování
    const pagingHandler = (number) => {
        setActivePage(number)
    }

    // Změna vylistovaných dat podle stránky
    useEffect(() => {
        setListedData(data[activePage - 1]);
        // eslint-disable-next-line
    }, [activePage])

    // Vytvoření stránkování
    let pages = [];
    for (let page = 1; page <= data.length; page++) {
        pages.push(
            <Pagination.Item key={page} active={page === activePage} onClick={() => pagingHandler(page)}>
                {page}
            </Pagination.Item>
        );
    }

    const imgSource = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
    const dateFormat = (date) => {
        const d = new Date(date);
        return d.toLocaleDateString() + ' ' + d.toLocaleTimeString()
    }

    const searchHandler = (event) => {
        if (event.currentTarget.value.length) {
            setSearch(true);
            const data = Object.values(all).filter(item =>
                item.lastName.toLowerCase().includes(event.currentTarget.value.toLowerCase()) || item.email.toLowerCase().includes(event.currentTarget.value.toLowerCase())
            );

            setListedData(data);
        } else {

            setSearch(false);
            const chunkedArray = chunkArray(Object.values(all), perPage);
            setListedData(chunkedArray[0])
        }

    }

    const downloadMedia = (event) => {

        event.preventDefault();
        const source = event.target.href;
        axios.get(source, {responseType: 'arraybuffer'})
            .then(response => {
                // Vytvoření Blob (Binary Large Object) ze stažených dat
                const blob = new Blob([response.data], {type: 'application/octet-stream'});

                // Vytvoření odkazu pro stažení souboru
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = event.target.dataset.file;

                // Přidání odkazu do DOMu
                document.body.appendChild(downloadLink);

                // Simulace kliknutí na odkaz pro spuštění stahování
                downloadLink.click();

                // Odstranění odkazu ze DOMu
                document.body.removeChild(downloadLink);
            })
            .catch(error => {
                console.error('Chyba při stahování souboru', error);
            });
    };

    const removeConfimationHandler = (event) => {

        all.filter((item) => {
            if (item.id === event.currentTarget.dataset.id) {
                setRemoveConfirmationContent(item);
                return {...item}

            }
        });

        setRemoveConfirmation(true);
    }

    const cancelRemove = () => {
        setRemoveConfirmation(false);
        setRemoveConfirmationContent([])
    }

    const removeHandler = async () => {

        try {

            const formData = new FormData();
            formData.append('token', localStorage.getItem('login'))
            formData.append('action', 'removeItem');
            formData.append('item', JSON.stringify(removeConfirmationContent));
            const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
            const response = await axios({
                    url: apiUrl + '/backend/admin/data.php',
                    method: 'post',
                    data: formData,
                }
            );
            if (response.data.status) {
                const updateItems = all.filter(item => item.id !== removeConfirmationContent.id);
                setAll(updateItems);
                setRemoveConfirmationContent([]);
                setRemoveConfirmation(false);

            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        const chunkedArray = chunkArray(Object.values(all), perPage);
        setListedData(chunkedArray[0])
    }, [all]);

    const returnState = (state, date='')=> {
        const registrationDate = new Date(date);
        const dateTest = new Date('2024-05-17 09:00:00');
        if (registrationDate > dateTest) {
            return state === '1' ? <span style={{color: 'green'}}>Potvrzena</span> :
                <span style={{color: 'red'}}>Čeká na potvrzení</span>
        }
        return <span style={{color: 'green'}}>Považována za potvrzenou</span>
    }
    return (
        <Fragment>
            <Container style={{marginTop: '5rem'}}>
                <h1 className="text-center">Seznam registrací</h1>
                {!dataLoaded &&
                    <Container style={{marginTop: '10px'}}>
                        <p className="text-center">Načítám data...</p>
                    </Container>
                }
                {dataLoaded &&
                    <Fragment>
                        <DownloadButton/>
                        <div style={{display: "flex", justifyContent: "center", marginTop: '30px'}}>
                            <input className="form-control" type="search" onChange={searchHandler}
                                   placeholder="Vyhledat podle příjmení nebo e-mailu" style={{maxWidth: '400px'}}/>
                        </div>
                        <Table responsive striped bordered hover variant="dark" style={{marginTop: '30px'}}>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Jméno a příjmení</th>
                                <th>Datum registrace</th>
                                <th>IP adresa</th>
                                <th>Detail</th>
                                <th>PDF</th>
                                <th>Stav potvrzení</th>
                                <th>Odstranit</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Array.isArray(listedData) && listedData.length > 0 && listedData.map((item, key) => (
                                    <tr key={item.id}>
                                        <td>{item.id}</td>
                                        <td>{item.firstName + ' ' + item.lastName}</td>
                                        <td>{item.date}</td>
                                        <td>{item.ip_address}</td>
                                        <td><span style={{cursor: "pointer"}}
                                                  onClick={() => handleShow(item.id)}>Zobrazit</span>
                                        </td>
                                        <td><span style={{cursor: "pointer"}} onClick={downloadHandler}
                                                  data-file={item.file}>Stáhnout</span>
                                        </td>
                                        <td>{returnState(item.confirmed, item.date)}</td>
                                        <td><Button variant={"danger"} data-id={item.id}
                                                    onClick={removeConfimationHandler}>Smazat</Button></td>
                                    </tr>
                                )
                            )}
                            </tbody>
                        </Table>

                        {pages.length > 1 && !search &&
                            <Pagination id="pagination">{pages}</Pagination>
                        }
                    </Fragment>
                }
            </Container>
            <Modal onHide={() => setShow(false)} show={show} size="lg" variant={"dark"}>
                {Object.values(modalContent).length &&
                    <Fragment>
                        <Modal.Header closeButton={true}>
                            <Modal.Title>{modalContent.firstName + ' ' + modalContent.lastName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table responsive striped bordered hover variant="light" style={{marginTop: '30px'}}>
                                <tbody>
                                <tr>
                                    <td width={"50%"}>Stav potvrzení registrace</td>
                                    <td>{returnState(modalContent.confirmed)}</td>
                                </tr>
                                <tr>
                                    <td width={"50%"}>Datum registrace</td>
                                    <td>{dateFormat(modalContent.date)}</td>
                                </tr>
                                <tr>
                                    <td>IP adresa</td>
                                    <td>{modalContent.ip_address}</td>
                                </tr>
                                <tr>
                                    <td>E-mail</td>
                                    <td>{modalContent.email}</td>
                                </tr>
                                <tr>
                                    <td>Telefon</td>
                                    <td>{modalContent.phone}</td>
                                </tr>
                                <tr>
                                    <td>Kraj</td>
                                    <td>{modalContent.address}</td>
                                </tr>
                                <tr>
                                    <td>Věk</td>
                                    <td>{modalContent.age}</td>
                                </tr>

                                <tr>
                                    <td>Pohlaví</td>
                                    <td>{modalContent.sex}</td>
                                </tr>
                                <tr>
                                    <td>V jakém věkovém rozmezí hledáte svůj protějšek?</td>
                                    <td>{modalContent.age_limit}</td>
                                </tr>
                                <tr>
                                    <td>Jak byste popsal/a svou sexuální orientaci?</td>
                                    <td>{modalContent.sexual_orientation}</td>
                                </tr>
                                <tr>
                                    <td>Jaké pohlaví hledáte</td>
                                    <td>{modalContent.search_for ? modalContent.search_for.replaceAll(',', ', ') : null}</td>
                                </tr>
                                <tr>
                                    <td>Jak byste popsal/a svůj vzhled?</td>
                                    <td>{modalContent.style_description}</td>
                                </tr>
                                <tr>
                                    <td>Jak byste popsal/a své tělo?</td>
                                    <td>{modalContent.body_description}</td>
                                </tr>
                                <tr>
                                    <td>Už jste se někdy svlékli na veřejnosti?</td>
                                    <td>{modalContent.naked_public}</td>
                                </tr>
                                <tr>
                                    <td>Máte nějaké neobvyklé tělesné rysy?</td>
                                    <td>{modalContent.body_anomaly.length ? modalContent.body_anomaly : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Jaká je vaše nejlepší část těla?</td>
                                    <td>{modalContent.body_best_part.length ? modalContent.body_best_part : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Čím se lišíte od ostatních?</td>
                                    <td>{modalContent.differences.length ? modalContent.differences : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Myslíte, že si budete jistí, když budete nazí a vaše tělo budou ostatní
                                        rozebírat a
                                        hodnotit?
                                    </td>
                                    <td>{modalContent.self_confidence.length ? modalContent.self_confidence : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Jak sebevědomí si myslíte, že budete při diskuzi a kritice nahých těl ostatních
                                        lidí?
                                    </td>
                                    <td>{modalContent.confidence_others.length ? modalContent.confidence_others : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Počet načtení stránky pro upload videa</td>
                                    <td>{modalContent.casting_page_view}x</td>
                                </tr>
                                <tr>
                                    <td>Počet nahraných médií</td>
                                    <td>{modalContent.media ? modalContent.media.length : 0}</td>
                                </tr>
                                </tbody>
                            </Table>
                            {modalContent.media &&
                                <Fragment>
                                    <h3 style={{marginTop: '30px'}}>Přiložená média</h3>
                                    <div style={{display: "flex", flexWrap: "wrap", gap: 10, marginTop: '10px'}}>

                                        {modalContent.media.map((item, key) => {
                                                let html;
                                                if (item.type === 'img') {
                                                    html = <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: 10,
                                                        alignItems: "center"
                                                    }}>
                                                        <img
                                                            style={{width: '300px', objectFit: "cover", aspectRatio: 1}}
                                                            src={`${imgSource}/backend/api/media/${modalContent.id}/${item.file}`}
                                                            alt=""/>
                                                        <a style={{color: '#000'}}
                                                           onClick={downloadMedia}
                                                           href={`${imgSource}/backend/api/media/${modalContent.id}/${item.file}`}
                                                           data-file={item.file}>stáhnout</a>
                                                    </div>


                                                }

                                                if (item.type === 'video') {
                                                    html = <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: 10,
                                                        alignItems: "center"
                                                    }}>
                                                        <video controls={true}
                                                               style={{
                                                                   width: '300px',
                                                                   objectFit: "cover",
                                                                   aspectRatio: 1
                                                               }}
                                                               src={`${imgSource}/backend/api/media/${modalContent.id}/${item.file}`}
                                                        />
                                                        <a style={{color: '#000'}}
                                                           onClick={downloadMedia}
                                                           href={`${imgSource}/backend/api/media/${modalContent.id}/${item.file}`}
                                                           data-file={item.file}>stáhnout</a>
                                                    </div>
                                                }

                                                return html;

                                            }
                                        )}

                                    </div>
                                </Fragment>
                            }
                        </Modal.Body>
                    </Fragment>
                }
                <Modal.Footer style={{display: "flex", justifyContent: "center"}}>
                    <Button variant="secondary" onClick={handleClose}>
                        Zavřít
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal onHide={() => setRemoveConfirmation(false)} show={removeConfirmation} size="md" variant={"dark"}>
                <Fragment>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Potvrzení smazání</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <strong>Opravdu si přejete smazat přihlášku na
                            jméno:</strong><br/> {`${removeConfirmationContent.firstName} ${removeConfirmationContent.lastName} (${removeConfirmationContent.email})`}<br/><br/>
                        <strong><span style={{color:'#D90000'}}>Tato akce je nevratná!</span></strong>
                    </Modal.Body>
                </Fragment>

                <Modal.Footer style={{display: "flex", justifyContent: "center"}}>
                    <Button variant="outline-secondary" onClick={cancelRemove}>
                        Zrušit
                    </Button>
                    <Button variant="danger" onClick={removeHandler}>
                        Ano, smazat!
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default List;
