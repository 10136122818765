import {Outlet} from "react-router-dom";

const AdminPanelRoot = () => {

    return (
        <Outlet/>
    )
}

export default AdminPanelRoot;
