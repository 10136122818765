import {Form} from "react-bootstrap";
import styles from './Input.module.css';
import parse from "html-react-parser";

const Input = (props) => {

    const {id, name, type, multiple, label, placeholder, onChange, onBlur, error, touched, value, readOnly} = props;
    const styledError = <span style={{color: '#D90000', fontSize: '14px'}}>{error}</span>

    return (
        <Form.Group className={`${styles['form-input']} mb-3`} controlId={id}>
            <Form.Label>{parse(label)} {error && touched ? styledError : ''}</Form.Label>
            <Form.Control
                readOnly={readOnly}
                type={type}
                value={value}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                multiple={multiple}
            />
        </Form.Group>
    )
}
export default Input
