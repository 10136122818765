import {Container} from "react-bootstrap";
import React from "react";

const Header = (props) => {
    return (
        <Container>
            <ul style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: '2rem',
                listStyle: 'none'
            }}>
                <li style={{cursor: 'pointer'}} onClick={props.logoutHandler}>Odhlásit se</li>
            </ul>
        </Container>
    )
}

export default Header;
