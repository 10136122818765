import {useLocation, useNavigate} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import axios from "axios";
import styles from "../components/Casting/CastingForm.module.css";

const Confirmation = () => {


    const location = useLocation().search.slice(1);
    const hashParam = new URLSearchParams(location).get("id");
    const [userData, setUserData] = useState();
    const [response, setResponse] = useState(false);
    const navigate = useNavigate();

    const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

    const fetchData = async () => {

        try {
            const response = await axios({
                url: apiUrl + '/backend/api/castingData.php',
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
                params: {hashParam}
            });
            if (response.data.status) {
                setUserData(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (hashParam?.length) {
            fetchData();
        }
        else{
            navigate('/')
        }
    }, [])

    const confirmHandler = async () => {
        try {
            const response = await axios({
                url: apiUrl + '/backend/api/applications.php?confirmation=' + hashParam,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                }
            });
            if (response.data.status) {
                setResponse(response.data.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        console.log('data', userData)
        confirmHandler();
    }, [userData, setUserData])

    return (
        <Fragment>

            {response &&
            <div className={"text-center"} id={styles['casting-form']}>
                <h1>Děkujeme!</h1>

                <p>Vaše registrace byla potvrzena!</p>
                <p>Vybraným zájemcům se ozveme s&nbsp;pozváním do dalšího kola castingu.</p>
            </div>
            }
            {!response &&

                <div className={"text-center"} id={styles['casting-form']}>
                    <h1>Chyba!</h1>
                    <p>Tato registrace nebyla nalezena!</p>
                </div>
            }
        </Fragment>
    )
}
export default Confirmation;
