import React, {Fragment, useEffect, useState} from "react";
import {Form, Button, Alert} from "react-bootstrap";
import "./login.css";
import axios from "axios";
import List from "./Components/List";
import Header from "./Components/Header";
import {Link} from "react-router-dom";


const Admin = () => {
    const [inputUsername, setInputUsername] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [isLogged, setIsLogged] = useState(!!localStorage.getItem('login'));

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await delay(500);

        const formData = new FormData();
        formData.append('action', 'login');
        formData.append('username', inputUsername);
        formData.append('password', inputPassword);

        try {
            const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

            const response = await axios({
                url: apiUrl + '/backend/admin/login-jwt.php',
                method: 'post',
                data: formData
            });

            if (response.data.status) {
                setLoading(false);
                setInputUsername('');
                setInputPassword('');
                localStorage.setItem('login', response.data.token);
                setIsLogged(true);
            } else {
                setShow(true);
            }

        } catch (error) {
            alert('Chyba při odeslání požadavku!')
        }

        setLoading(false);
    };

    const authCheck = async () => {

        if (localStorage.getItem('login') !== null) {
            try {

                const formData = new FormData();
                formData.append('action', 'auth');
                formData.append('token', localStorage.getItem('login'));
                const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
                const response = await axios({
                        url: apiUrl + '/backend/admin/login-jwt.php',
                        method: 'post',
                        data: formData,
                    }
                );

                if (response.data.status) {
                    setIsLogged(true);
                    return true;
                } else {
                    setIsLogged(false);
                    localStorage.removeItem('login')
                    return false;
                }

            } catch
                (error) {
                alert('Nemáte oprávnění!');
                logoutHandler();
            }
        } else {
            logoutHandler();
            return false;
        }
    }

    const logoutHandler = () => {
        localStorage.removeItem('login');
        setIsLogged(false);
    }

    useEffect(()=>{
        authCheck();
    },[])

    function delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    return (
        <Fragment>
            {isLogged &&
                <Fragment>
                    <Header logoutHandler={logoutHandler}/>
                    <List checkAuth={authCheck}/>
                </Fragment>
            }
            {!isLogged &&
                <div className="sign-in__wrapper">

                    <div className="sign-in__backdrop"></div>

                    <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>

                        <div className="h4 mb-2 text-center">Přihlášení</div>

                        {show ? (
                            <Alert
                                className="mb-2"
                                variant="danger"
                                onClose={() => setShow(false)}
                                dismissible
                            >
                                Neplatné jméno nebo heslo!
                            </Alert>
                        ) : (
                            <div/>
                        )}
                        <Form.Group className="mb-2" controlId="username">

                            <Form.Control
                                type="text"
                                value={inputUsername}
                                placeholder="Uživatelské jméno"
                                onChange={(e) => setInputUsername(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="password">

                            <Form.Control
                                type="password"
                                value={inputPassword}
                                placeholder="Heslo"
                                onChange={(e) => setInputPassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        {!loading ? (
                            <Button className="w-100" variant="primary" type="submit">
                                Přihlásit
                            </Button>

                        ) : (
                            <Button className="w-100" variant="primary" type="submit" disabled>
                                Přihlašování...
                            </Button>
                        )}

                        <div style={{display:"flex", justifyContent:"center", marginTop:'20px'}}>
                        <Link to="/" style={{color:'#000'}}>Zpět na web</Link>
                        </div>

                    </Form>

                </div>
            }
        < /Fragment>
    );
};

export default Admin;
